<template>
  <div>
    <GenericTable
      ref="lista"
      name="equipamento"
      title="Equipamentos"
      add-route="equipamentoAdicionar"
      edit-route="equipamentoEditar"
      :cols="['Unidade', 'Nome do Equipamento']"
      :cols-name="['unidade', 'nome']"
      :cols-map="i => [i.unidade_equipamento.nome, i.nome]"
      :route="equipamentoRoute"
      @clear-filters="clearFilters"
      :permissionsToWrite="['rw_esterilizacao']"
      idKey="id_equipamento"
      :filters="filters"
      :hasPagination="true"
      :noadd="noAdd"
    >
      <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Nome do Equipamento</label>
              <b-form-input
                v-model="filters.nome_equipamento"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Unidade</label>
              <GenericSelect
                name="unidade"
                :options="optionsUnidade"
                route="unidade"
                labelKey="nome"
                v-model="filters.id_unidade"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericTable>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import unidadeServices from '../../services/unidade';
import loginServices from '../../services/login';
import GenericSelect from '../../components/Form/GenericSelect';

const { getUnidades } = unidadeServices;

export default {
  components: {
    GenericSelect,
  },
  data() {
    return {
      equipamentoRoute: 'equipamento',
      hasBlocoOrCme: false,
      noAdd: false,
      filters: {
        nome_equipamento: null,
        id_unidade: null,
      },
      optionsUnidade: [],
    };
  },
  computed: {
    ...mapState(['previousRoute', 'filtersStored']),
    ...mapState({
      currentUnidadeStore: (state) => state.currentUnidade,
    }),
  },
  async created() {
    const user = loginServices.getUser();

    const { hasBloco, hasCme } = user;

    this.hasBlocoOrCme = hasBloco || hasCme;

    if (!this.hasBlocoOrCme) {
      this.filters.id_unidade = this.currentUnidadeStore.id_unidade;
      this.noAdd = true;
    }

    const allowedRoutesToKeepFilters = [
      'equipamentoEditar',
      'equipamentoAdicionar',
    ];
    if (this.filtersStored.equipamento) {
      if (allowedRoutesToKeepFilters.includes(this.previousRoute.name)) {
        this.filters = { ...this.filtersStored.equipamento };
      } else {
        this.$store.commit('setFiltersStored', {});
      }
    }
    const units = await getUnidades();
    const mappedUnits = units.map((unit) => ({
      value: unit.id_unidade,
      text: unit.nome,
    }));
    this.optionsUnidade = [...this.optionsUnidade, ...mappedUnits];
  },
  methods: {
    clearFilters() {
      this.filters.id_unidade = null;
      this.filters.nome_equipamento = null;
    },
  },

};
</script>
